.video-teaser {
    
    height: 300px;
    overflow: hidden;
    
    a {
    
        .video-teaser-item {
            position: relative;
            height: 300px;
            float: left;
            border-right: 4px solid #fff;
            overflow: hidden;
            -webkit-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
            -moz-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
            -o-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
            transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                       
            img {
                position: relative;
                left: 0;
                width: 350px;
                height: 300px;
                -webkit-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                -moz-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                -o-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
            }
            
            &:not(.hover) img {
                left: -190px;
            }
            
            .video-teaser-caption {
                position: absolute;
                bottom: 0;
                width: 350px;
                padding: 10px 15px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;
                -webkit-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                -moz-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                -o-transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                transition: all 0.8s ease-in-out,all 0.8s ease-in-out;
                
                h6 {
                    font-size: 17px;
                    border-bottom: 1px solid #fff;
                    text-transform: uppercase;
                }
                
                p {
                    margin: 0;
                    font-size: 13px;
                    line-height: 15px;
                }
                
                a.btn.btn-default {
                    position: absolute;
                    right: 15px;
                    bottom: 10px;
                    padding: 8px 3px;
                    color: #fff;
                    background-color: transparent;
                    border: 1px solid #fff;
                    border-radius: 0;
                    font-weight: 300;
                    text-transform: uppercase;
                }
            }
            
            &:hover .video-teaser-caption {
                opacity: 1;
            }
        }
        
        &:last-of-type {
            
            .video-teaser-item {
                border-right: 0;
            }
            
        }
        
    }
    
}