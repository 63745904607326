.lawfield-teaser {
    
    a {
        text-decoration: none;
    
        .lawfield-teaser-icon {
            display: -webkit-flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 90px;
            height: 90px;
            margin: 20px auto 15px;
            border: 4px solid $blue;
            border-radius: 50%;
            
            .glyphicons {
                margin: 0 auto;
                font-size: 35px;
            }
        }
        
        p {
            margin-bottom: 20px;
            font-family: 'Oswald';
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}