@import 'fonts.scss';
@import 'colors.scss';

body {
  font-family: 'Lato', Arial;
  font-size: 16px;
  font-weight: 300;

  &.menu-open {
    overflow: hidden;
  }
}

hr {
  margin-top: 25px;
  margin-bottom: 25px;
  border-top: 1px solid $blue;
}

b,
strong {
  font-weight: 400;
  color: $blue;
}

a,
a:focus,
a:hover {
  color: $blue;
}

h1,
h2 {
  margin: 0px;
  padding: 10px 15px;
  background-color: $blue;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;

  sub {
    bottom: 0;
    font-size: 13px;
    font-weight: normal;
  }
}

h2 {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 0px;
  font-size: 20px;
}

.typo3-neos-nodetypes-headline {
  > div {
    text-align: center;
    overflow: hidden;

    > h3 {
      display: inline-block;
      position: relative;
      font-size: 26px;
      font-weight: bold;
      color: $blue;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    > h3::before,
    > h3::after {
      content: "";
      position: absolute;
      border-top: 1px solid $blue;
      bottom: 12px;
      width: 600px;
    }

    > h3::before {
      right: 100%;
      margin-right: 55px;
    }

    > h3::after {
      left: 100%;
      margin-left: 55px;
    }
  }
}


h4 {
  position: relative;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  color: $blue;
  text-align: left;
  padding-left: 30px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 21px;
    height: 20px;
    background: url('../Images/headline-logo.png') no-repeat;
    left: 0px;
    top: 0px;
  }
}

h5 {
    margin: 0;
    font-size: 17px;
}

p {
  margin-bottom: 20px;
}

.rafincke-mainwebsite-section {
  padding: 30px 15px;
}

.typo3-neos-nodetypes-image {
  + .rafincke-mainwebsite-section {
    margin-top: -20px;
  }

  &.show-border {
    figure {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        margin: 5px;
        border: 1px solid $blue;
      }
    }
  }
}

.glyphicons:before {
  padding: 0px;
}

#map-overlay {
  z-index: 99 !important;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 5px;
    border: 1px solid $blue;
  }
}

.page {
  .page-inner {
    position: relative;
    background-color: #fff;
    margin: 15px -15px 15px -15px;

    header {
      position: relative;
      padding-top: 90px;
      background-color: #f2f2f2;

      .navigation-outer {
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        border: none;
        z-index: 100;

        .navigation {
          position: relative;

          &.container:after {
            content: '';
            position: absolute;
            left: 15px;
            bottom: -8px;
            height: 8px;
            width: calc(100% - 30px);
            background: rgba(0,0,0,1);
            background: -moz-linear-gradient(top, rgba(0,0,0,1) -1000%, rgba(255,255,255,0) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(100%, rgba(255,255,255,0)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,1) -1000%, rgba(255,255,255,0) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,1) -1000%, rgba(255,255,255,0) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,1) -1000%, rgba(255,255,255,0) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,1) -1000%, rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );

          }

          .navigation-inner {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 20px;
            height: 90px;
            background-color: #fff;
          }

          @import 'menu.scss';

        }
      }

      .image {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          margin: 5px;
          border: 1px solid $blue;
        }

        .image-caption {
          position: absolute;
          top: 15%;
          left: 50%;
          width: 48%;
          color: $blue;
          font-size: 12px;
          text-transform: uppercase;

          &:first-line {
            font-weight: bold;
          }
        }
      }
    }

    .sidebuttons {
        position: absolute;
        top: 150px;
        left: -45px;

        .btn {
            display: block;
            width: 45px;
            height: 70px;
            margin-bottom: 5px;
            padding-top: 25px;
            color: #fff;
            background-color: $blue;
            border: none;
            border-radius: 0;
            font-size: 20px;
        }
    }

    .content {
      position: relative;
      left: 0px;
      top: -20px;
      padding-left: 15px;
      padding-right: 15px;
      z-index: 2;

      .main {
        box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.10);

        > .main-inner {
          background-color: #fff;
          min-height: 250px;
          margin-bottom: 10px;

          @import 'video-teaser.scss';
          @import 'lawfield-teaser.scss';
          @import 'block-link.scss';
          @import 'bordered-content.scss';
          @import 'collapsibles.scss';
          @import 'gallery.scss';
          @import 'contact-person.scss';
          @import 'specialist-banner.scss';
          @import 'button.scss';
          @import 'form.scss';

        }
      }

      footer {
        padding-top: 25px;
        color: $blue;

        .footer-menu {
            margin-top: 15px;
        }

        .glyphicons-copyright-mark {
          font-size: 18px;

          + span {
            display: inline-block;
            line-height: 20px;
          }
        }

        .glyphicons-upload {
          font-size: 14px;
          padding-top: 5px;
        }
      }
    }

    &.footer-border {
      .content {
        .main {
          border-bottom: 30px solid $blue;

          > .main-inner {
            border-bottom: 1px solid $blue;
          }
        }
      }
    }
  }
}

#swipebox-overlay {
    background-color: rgba(13, 13, 13, 0.7);
}
