@import 'glyphicons.scss';

@font-face {
  font-family: 'Oswald';
  src: url('../Fonts/Oswald-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Lato';
  src: url('../Fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}