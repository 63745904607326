.quick-nav-toggle {
    position: relative;
    top: 15px;
    margin-right: 30px;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    
    &:last-of-type {
        margin-right: 60px;
    }
}

.navbar-toggle {
    display: block;
    width: 40px;
    height: 40px;
    margin: 2px 0 0 0;
    padding: 0;
    
    .icon-bar {
        display: block;
        position: absolute;
        width: 30px;
        height: 5px;
        background-color: $blue;
        cursor: pointer;
        -webkit-transition: all 0.5s ease-in-out,all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out,all 0.5s ease-in-out;
        -o-transition: all 0.8s ease-in-out,all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out,all 0.5s ease-in-out;
        
        &:nth-child(2) {
            top: 8px;
        }
        
        &:nth-child(3) {
            top: 14px;
        }
        
        &:nth-child(4) {
            top: 24px;
        }
    }
    
    &:not(.collapsed) {
        .icon-bar {
            &:nth-child(2) {
                top: -30px;
                opacity: 0;
            }
            
            &:nth-child(3) {
                top: 14px;
                transform: rotate(130deg);
            }
            
            &:nth-child(4) {
                top: 14px;
                transform: rotate(50deg);
            }
        }
    }
}

#navbar-collapse {
    background-color: #fff;
    
    .menu-row {
        width: calc(100% + 30px);
        height: calc(100vh - 90px);
        padding-right: 30px;
        overflow-y: scroll;
        
        .menu-col {
            margin: 15px 10px;
            
            h5, h6 {
                font-size: 16px;
                text-transform: uppercase;
                line-height: normal;
            }
            
            h6 {
                margin-top: 0;
                font-size: 12px;
            }
            
            ul {
                padding: 0;
                list-style: none;
                
                li {
                    margin: 10px 0;
                    font-size: 16px;
                    word-wrap: break-word;
                }
            }
            
            .menu-description {
                font-size: 12px;
            }
			
			&:nth-child(3) {
				.menu-sub-col:nth-last-child(1) {
					ul {
					  li {
						&:nth-last-child(2) {
							font-weight: bold;
						}
					  }
					}
				}
			}
        }
    }
}

#lawyers-nav-collapse {
    ul {
        display:table;
        float:none;
        width:100%;
        padding: 30px;
        background-color: $blue;
        text-align:center; 
        list-style:none;
        
        li {
            float:none;
            display:table-cell;
            
            a {
                display:block;
                color: #fff;
            }
        }
    }
}

#lawfields-nav-collapse {
    background-color: $blue;
    
    .row {
        padding: 30px 30px 25px;
    }
    
    h5 {
        padding-bottom: 15px;
        color: #fff;
        font-family: 'Oswald';
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
    }
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        
        li {
            float: left;
            padding-bottom: 5px;
            
            &:not(:last-child):after {
                content:"\00b7";
                padding: 0 10px;
                color: #fff;
            }
            
            a {
                color: #fff;
            }
        }
    }
}

#service-nav-collapse {
    ul {
        display:table;
        float:none;
        width:100%;
        padding: 30px;
        background-color: $blue;
        text-align:center; 
        list-style:none;
        
        li {
            float:none;
            display:table-cell;
            
            a {
                display:block;
                color: #fff;
            }
        }
    }
}
