.obisconcept-ajaxform-ajaxform .fa-spinner.fa-pulse {
    position: relative;
    left: calc(50% - 20px);
    margin-top: 100px;
    color: $blue;
    font-size: 40px;
}

form {
    .form-control {
        min-height: 45px;
        border: 1px solid $blue;
        border-radius: 0;
    }
    
    .form-navigation {
        .nav-pills {
            height: 30px;
            
            .next {
                position: absolute;
                right: 15px;
                
                .btn {
                    padding-right: 0;
                    
                    &:after {
                        content: '\E218';
                        position: relative;
                        top: 5px;
                        margin-left: 5px;
                        color: $blue;
                        font-family: 'Glyphicons Regular';
                        font-size: 18px;
                    }
                }
            }
            
            .previous {
                .btn {
                    padding-left: 0;
                    
                    &:before {
                        content: '\E217';
                        position: relative;
                        top: 5px;
                        margin-right: 5px;
                        color: $blue;
                        font-family: 'Glyphicons Regular';
                        font-size: 18px;
                    }
                }
            }
            
            .btn {
                border: none;
                font-family: 'Oswald';
                
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}