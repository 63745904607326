.block-link {
    margin-bottom: 10px;
    
    a {
        color: #000;
        
        .glyphicons {
            float: left;
            margin-right: 5px;
            color: $blue;
        }
    }
}