.panel-group {
    margin: 30px 0;
    
    .panel {
        border: none;
        margin-bottom: 15px;
    
        .panel-heading {
            padding: 0;
            border: none;
            background-color: transparent;
            
            h4 {
                padding: 0 0 5px 0;
                color: $blue;
                border-bottom: 1px solid $blue;
                font-size: 18px;
                text-transform: uppercase;
                
                &:before {
                    display: none;
                }
                
                &.large-title {
                    font-size: 22px;
                }
                
                a {
                    display: block;
                    position: relative;
                    padding-right: 90px;
                    word-break: break-word;
                    
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                    
                    .button {
                        position: absolute;
                        right: 0;
                        
                        &:before {
                            content: 'schließen';
                            position: relative;
                            top: -5px;
                            right: 5px;
                            color: #000;
                            font-size: 14px;
                            text-transform: none;
                        }
                        
                        &:after {
                            content: '\E192';
                            font-family: 'Glyphicons Regular';
                        }
                    }
                }
                
                a.collapsed {
                    .button:before {
                        content: 'öffnen';
                    }
                    
                    .button:after {
                            content: '\E191';
                    }
                }
            }
        }
        
        .panel-body {
            padding: 15px 0;
            border: none;
        }
    }
}