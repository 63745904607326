.obisconcept-bootstrap-button {
    display: block;
    position: relative;
    margin: 15px 0;
    
    .btn {
        display: block;
        height: auto;
        min-height: 35px;
        padding: 10px 55px 10px 10px;
        color: $blue;
        border: 1px solid $blue;
        border-radius: 0;
        white-space: normal;
        
        &:hover,
        &:focus,
        &:active,
        &:visited {
            background-color: transparent;
        }
        
        &:after {
            content: '\E152';
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            padding-top: 10px;
            color: #fff;
            background-color: $blue;
            font-family: 'Glyphicons Regular';
            text-align: center;
        }
        
        span {
            word-wrap: break-word;
        }
    }
    
    .btn[href$='.pdf']:after,
    .btn[href$='.doc']:after,
    .btn[href$='.docx']:after {
        content: '\E037';
    }
}