.specialist-banner {
    position: relative;
    margin: 0;
    
    a {
        text-decoration: none;
    }
    
    .specialist-banner-image {
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            border: 1px solid $blue;
        }
        
        .specialist-banner-caption {
            position: absolute;
            top: 30px;
            right: 30px;
            color: $blue;
            font-family: 'Oswald';
            font-size: 17px;
            text-transform: uppercase;
        }
    }
    
    .specialist-banner-title {
        padding: 15px;
        color: $blue;
        font-family: 'Oswald';
        text-transform: uppercase;
        
        h4 {
            margin: 0;
            padding-left: 0;
            font-size: 22px;
            font-weight: bold;
            
            &:before {
                display: none;
            }
        }
        
        p {
            margin: 0;
        }
    }
    
    .specialist-banner-text {
        padding: 15px;
        
        p {
            color: #000;
            margin-bottom: 4px;
            
            em {
                font-family: 'Oswald';
                font-weight: bold;
                font-style: normal;
            }
            
            a[href*='mailto:']:before {
                content: '\E011';
                position: relative;
                top: 2px;
                margin-right: 5px;
                color: #000;
                font-family: 'Glyphicons Regular';
                text-decoration: none;
            }
        }
    }
}