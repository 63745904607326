@media (min-width: $sm-view) {
  h1 {
    padding: 35px 70px 35px 70px;
    font-size: 26px;
      sub {
        font-size: 16px;
      }
  }
  h4 {
      margin-bottom: 25px;
  }
  .rafincke-mainwebsite-section {
    padding: 40px 70px;
  }
  .typo3-neos-nodetypes-image {
    &.show-border {
      figure {
        &:after {
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        margin: 15px;
        }
      }
    }
  }
  #map-overlay {
    &:after {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      margin: 15px;
    }
  }
  .page {
    .page-inner {
      margin: 35px 0 60px;
      box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.10);

      header {
        .navigation-outer {
          .navigation {
            .navigation-inner {
              padding-left: 35px;
              padding-right: 35px;
            }
            #navbar-collapse {
              .menu-row {                    
                .menu-col {
                  width: calc(20% - 20px);
                  float: left;
                  margin-top: 33px;
                  padding: 10px;
                  border: 1px solid $blue;
                  
                  &:nth-child(1),
                  &:nth-child(2),
                  &:nth-child(3) {
                    min-height: 610px;
                  }
                  
                  &:nth-child(1) {
                      width: calc(20% - 20px);
                      
                      h5 {
                        left: -4px;
                      }
                  }
                  
                  &:nth-child(2),
                  &:nth-child(3) {
                      width: calc(30% - 20px);
                  }
                     
                  &:nth-child(3) {
                    width: calc(50% - 20px);
                    
                    ul {
                      li {
                        font-size: 14px;
                      }
                    }
                  }
                  
                  &:nth-child(4) {
                    width: calc(50% - 20px);
                    clear: both;
                  }
				  
                  &:nth-child(5) {
                    width: calc(50% - 20px);
                  }
                  
                  h5 {
                    display: table;
                    position: relative;
                    top: -20px;
                    margin: 0 auto -10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    background-color: #fff;
                    text-align: center;
                  }
                      
                  .menu-sub-col {
                    width: 50%;
                    float: left;
                    padding: 10px;
                  }
                }
              }
            }
          }
        }
        
        .image {
          &:after {
            width: calc(100% - 49px);
            height: calc(100% - 49px);
            margin: 25px;
          }
          
          .image-caption {
            top:25%;
            width: 46%;
            font-size: 16px;
            
            &:before {
              content: '';
              position: absolute;
              top: 30%;
              left: calc(-25% - 15px);
              width: 25%;
              border-bottom: 1px solid $blue;
            }
            
            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: -15px;
              height: 100%;
              border-right: 1px solid $blue;
            }
          }
        }
      }

      .content {
        top: -70px;
        padding-left: 50px;
        padding-right: 50px;
        
        .main {
          > .main-inner {
            .lawfield-teaser {
                a {
                    .lawfield-teaser-icon {
                        width: 95px;
                        height: 95px;

                        .glyphicons {
                            font-size: 36px;
                        }
                    }
                    
                    p {
                        font-size: 18px;
                    }
                }
            }
            
            .specialist-banner {
                .specialist-banner-image {
                    &:after {
                        top: 15px;
                        left: 15px;
                        width: calc(100% - 30px);
                        height: calc(100% - 30px);
                    }
                    
                    .specialist-banner-caption {
                        position: absolute;
                        top: 85%;
                        left: 25px;
                        font-size: 22px;
                        transform: rotate(270deg);
                        transform-origin: 0 0;
                        text-transform: uppercase;
                    }
                }
                
                .specialist-banner-title {
                    position: absolute;
                    width: 19%;
                    top: 20%;
                    left: 40%;
                    padding: 0;
                    font-size: 13px;
                    text-transform: uppercase;
                    
                    &:before {
                        content: '';
                        position: absolute;
                        bottom: -40px;
                        left: 30px;
                        height: 30px;
                        border-left: 1px solid $blue;
                    }
                    
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -40px;
                        left: 30px;
                        width: calc(100% - 80px);
                        border-bottom: 1px solid $blue;
                    }
                    
                    h4 {
                        font-size: 18px;
                    }
                }
                
                .specialist-banner-text {
                    position: absolute;
                    width: 38%;
                    top: 25%;
                    left: 60%;
                    padding: 0;
                    
                    p {
                        margin-bottom: 4px;
                        font-size: 15px;
                    }
                }
            }
          }
        }
        
        footer .footer-menu {
            margin-top: 0;
            text-align: right;
        }
      }
    }
  }
}

@media (min-width: $md-view) {
  .page {
    .page-inner {
      margin-top: 70px;
      margin-bottom: 120px;

      header {
        .navigation-outer {
          .navigation {
            .navigation-inner {
              padding-left: 80px;
              padding-right: 80px;
            }
            #navbar-collapse {
              .menu-row {                    
                .menu-col {
                  min-height: 600px !important;
                  
                  &:nth-child(1) {
                    width: calc(15% - 20px);
                    
                    h5 {
                        left: -5px;
                    }
                  }
                  
                  &:nth-child(2) {
                      width: calc(25% - 20px)
                  }
                  
                  &:nth-child(3) {
                    width: calc(40% - 20px);
                  }
                  
                  &:nth-child(4) {
                      clear: none;
                  }
                  &:nth-child(4), &:nth-child(5)  {
                      width: calc(20% - 20px);
					  min-height: 276px !important;
				  }
                }
              }
            }
          }
        }
      }

      .content {
        padding-left: 100px;
        padding-right: 100px;
        
        .main {
          > .main-inner {
            .video-teaser a .video-teaser-item:not(.hover) video {
              left: -90px;
            }
            .contact-person  {
                .contact-person-text {
                    font-size: 16px;
                }
                .contact-person-image .contact-person-caption {
                  top: 5%;
                  left: 5%;
                  font-size: 16px;
                }
            }
            
            .specialist-banner {
                .specialist-banner-image {
                    .specialist-banner-caption {
                        top: 72%;
                    }
                }
                
                .specialist-banner-title {
                    font-size: 14px;
                    
                    &:after {
                        width: calc(100% - 20px);
                    }
                    
                    h4 {
                        font-size: 20px;
                    }
                }
                
                .specialist-banner-text {
                    width: 32%;
                    top: 35%;
                    left: 64%;
                }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $lg-view) {
  .page {
    .page-inner {
      header .navigation-outer .navigation #navbar-collapse .menu-row .menu-col {
        min-height: 575px !important;
        
        &:nth-child(1) h5 {
            left: auto !important;
        }
        
        h6 {
          font-size: 15px;
        }
      }
      .content {
        .main {
          > .main-inner {
            .video-teaser a .video-teaser-item:not(.hover) video {
              left: -70px;
            }
            
            .contact-person .contact-person-image .contact-person-caption {
              top: 15%;
              left: 10%;
            }
            
            .contact-person .contact-person-text {
              padding: 30px;
            }
            
            .specialist-banner {
                .specialist-banner-image {
                    .specialist-banner-caption {
                        top: 56%;
                    }
                }
                
                .specialist-banner-title {
                    width: 25%;
                    font-size: 16px;
                    
                    &:after {
                        width: calc(100% - 60px);
                    }
                    
                    h4 {
                        font-size: 22px;
                    }
                }
                
                .specialist-banner-text {
                    width: 33%;
                    top: 40%;
                    left: 65%;
                    
                    p {
                        font-size: 18px;
                    }
                }
            }
          }
        }
      }
    }
  }
}