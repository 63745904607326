.award-badge {
  display: none;

  position: absolute;
  overflow: hidden;
  z-index: 1;

  height: 75%;
  top: 10px;
  right: 10px;

  @media (min-width: $sm-view) {
    height: 50%;
    top: 40px;
    right: 40px;
  }

  a {
    width: 100%;
    height: 100%;
  }

  img {
    max-height: 100%;
    width: auto;
  }
}

#p4a336291-cd98-edf2-2fe1-8931ba3f7a7e .award-badge.link {
  display: block;
}

#pac58ce35-b1b3-4da7-9061-072bec5004e5 .award-badge.nolink {
  display: block;
}
