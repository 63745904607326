.contact-person {
    position: relative;
    
    .contact-person-image {
        text-align: right;
        
        .contact-person-caption {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            font-family: 'Oswald';
            font-size: 14px;
            text-align: left;
            
            &:before {
                content: '';
                position: absolute;
                bottom: -50px;
                left: 50px;
                height: 40px;
                border-right: 1px solid $blue;
            }
            
            &:after {
                content: '';
                position: absolute;
                bottom: -50px;
                left: 50px;
                width: 40px;
                border-top: 1px solid $blue;
            }
        }
        
        img {
            display: inline-block;
            max-width: 50%;
            margin-right: 5%;
        }
    }
    
    .contact-person-text {
        padding: 15px;
        border: 1px solid $blue;
        font-size: 13px;
        
        h6 {
            margin-top: 0;
            color: $blue;
            font-size: 18px;
        }
        
        p:last-of-type {
            margin-bottom: 0;
        }
        
        a[href*='mailto:']:before {
            content: '\E011';
            position: relative;
            top: 2px;
            margin-right: 5px;
            color: #000;
            font-family: 'Glyphicons Regular';
            text-decoration: none;
        }
    }
}